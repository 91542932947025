import React from 'react';
import '../styles/pages/_e3kit.scss';
import Layout from 'src/layout/Layout';
import seoPage from '../../content/seo/e3kit.yml';
import logo from '../styles/assets/images/e3kit/e3kit-logo.svg';
import Intro from '../components/sections/intro/Intro';
import RedButton from '../components/buttons/RedButton';
import Title from '../components/title/Title';
import Features from '../components/sections/features/Features';
import FeaturesItem, { FeaturesItemProps } from '../components/sections/features/FeaturesItem';
import { CardContainer } from '../components/card/Card';
import ZoomImage from '../components/zoom/ZoomImage';
import Benefits, { BenefitsList } from '..//components/sections/benefits/Benefits';
import BenefitsItem, { BenefitsItemProps } from '..//components/sections/benefits/BenefitsItem';
import Standards from '../components/sections/standards/Standards';
import E3KitSnippet from '../../content/snippets/encrypt.md';
import Sample, { SampleTextContainer, SampleCode } from '../components/sections/sample/Sample';
import BlogPost, { BlogPostProps } from '../components/sections/blog/BlogPost';
import BlogPostList from '../components/sections/blog/BlogPostList';
import Conclusion from '../components/sections/conclusion/Conclusion';
import Cases from '../components/sections/cases/Cases';
import CaseItem, { CaseItemProps } from '../components/sections/cases/CasesItem';
import FavoriteTech from '../components/sections/favorite/FavoriteTech';

// Images
import oneToOneIcon from '../styles/assets/images/e3kit/one-to-one.svg';
import postQuantumIcon from '../styles/assets/images/e3kit/post-quantum.svg';
import doubleRatchetIcon from '../styles/assets/images/e3kit/double-ratchet.svg';
import encryptIcon from '../styles/assets/images/e3kit/encrypt.svg';
import schemeImg from '../styles/assets/images/e3kit/Scheme-e3kit.png';
import schemeImg2x from '../styles/assets/images/e3kit/Scheme-e3kit@2x.png';
import IconHealth from '../styles/assets/images/cases/Icon-Health.svg';
import IconFin from '../styles/assets/images/cases/Icon-Fin.svg';
import IconAuto from '../styles/assets/images/cases/Icon-Auto.svg';
import IconCloud from '../styles/assets/images/cases/Icon-Cloud.svg';
import IconIoT from '../styles/assets/images/cases/Icon-IoT.svg';
import IconIndustry from '../styles/assets/images/cases/Icon-Industry.svg';

const FEATURES: FeaturesItemProps[] = [
	{
		href: 'https://developer.virgilsecurity.com/docs/e3kit/end-to-end-encryption/',
		iconSrc: oneToOneIcon,
		title: 'One-to-one and group encryption',
	},
	{
		href: 'https://developer.virgilsecurity.com/docs/e3kit/end-to-end-encryption/post-quantum/',
		iconSrc: postQuantumIcon,
		title: 'Post-quantum encryption',
	},
	{
		href: 'https://developer.virgilsecurity.com/docs/e3kit/end-to-end-encryption/double-ratchet/',
		iconSrc: doubleRatchetIcon,
		title: 'Double Ratchet encryption',
	},
	{
		href: 'https://developer.virgilsecurity.com/docs/e3kit/end-to-end-encryption/temporary/',
		iconSrc: encryptIcon,
		title: 'Encryption for not yet registered users',
	},
];

const BENEFITS: BenefitsItemProps[] = [
	{
		title: 'Post-quantum cryptography support',
		children:
			'E3Kit provides encryption methods that use post-quantum algorithms (Falcon, Round5) to make your data quantum-resistant.',
	},
	{
		title: 'Compatible with any CPaaS provider',
		children:
			"E3Kit doesn't require any changes from platform providers and doesn't intersect with their infrastructure.",
	},
	{
		title: 'Easy to set up and integrate into new or existing projects',
		children:
			"End-to-end encryption is only a couple of code lines away from you - E3Kit comes in a convenient package and doesn't require any special knowledge of cryptography.",
	},
	{
		title: 'Protected cloud storage and recovery features for secret keys',
		children:
			'Virgil Security provides secure cloud storage for sensitive data of your users, in particular their private keys backup.',
	},
	{
		title: 'Access to encrypted data from multiple user devices',
		children:
			"Encrypted data can be accessed from several user's devices thanks to private keys synchronization.",
	},
	{
		title: 'Works with native secrets management systems',
		children: 'E3Kit is compatible with Keychain, Android Keystore, and many more.',
	},
];

const E3KIT_POSTS: BlogPostProps[] = [
	{
		title: 'How to End-to-End Encrypt in Messages Using Twilio and Virgil Security',
		meta: 'Rebecca Yarbrough — January 25th, 2019',
		img: '/images/posts/e3kit-and-Twilio-700x265-c-default.jpg',
		href: '/blog/end-to-end-encryption-for-twilio/',
	},
	{
		title: 'e3kit: An End-to-End Encryption SDK for Every Developer',
		meta: 'Rebecca Yarbrough — January 16th, 2019',
		img: '/images/posts/Blog-2-700x265-c-default.jpg',
		href: '/blog/e3kit-intro/',
	},
	{
		title:
			'Introducing e3kit for Firebase, a New End-to-End Encryption SDK with Key Functionalities Built In',
		meta: 'Rebecca Yarbrough — January 11th, 2019',
		img: '/images/posts/e3kit-and-Firebase-700x265-c-default.jpg',
		href: '/blog/e3kit-for-firebase/',
	},
];

const ALL_CASES: CaseItemProps[] = [
	{
		title: 'Healthcare',
		description: 'Make your communication platform and backend HIPAA-compliant.',
		icon: IconHealth,
	},
	{
		title: 'Financial',
		description:
			'Manage cardholder data and personal financial information within a PCI DSS-compliant system.',
		icon: IconFin,
	},
	{
		title: 'Automotive',
		description: 'Build IEEE-1609.2-compliant security solutions for protecting V2X communication.',
		icon: IconAuto,
	},
	{
		title: 'Cloud',
		description: 'Protect and manage access to sensitive data in the сloud storage.',
		icon: IconCloud,
	},
	{
		title: 'Internet of Things',
		description: 'Secure IoT devices from manufacturing to the end-user experience.',
		icon: IconIoT,
	},
	{
		title: 'Industry 4.0',
		description:
			'Build a fully protected industrial ecosystem and securely manage it across the stack.',
		icon: IconIndustry,
	},
];

const E3KitPage: React.FC = () => {
	return (
		<Layout seoPage={seoPage} className="e3kitPage">
			<Intro>
				<img src={logo} alt="e3kit logo" />
				<h1>
					E3Kit: End-to-end encryption framework
					<br />
					for protecting any communication
				</h1>
				<p>Secure any data in transit</p>
				<RedButton href="https://developer.virgilsecurity.com/docs/e3kit/">Start now</RedButton>
			</Intro>
			<FavoriteTech />
			<Features>
				<Title>
					<h2>Virgil E3Kit features</h2>
					<p>Choose your own use case to protect communications.</p>
				</Title>
				<CardContainer>
					{FEATURES.map((fc, index) => {
						return <FeaturesItem {...fc} key={index} />;
					})}
				</CardContainer>
			</Features>
			<section className="instruction">
				<div className="wrapper">
					<Title>
						<h2>How it works</h2>
						<p>
							E3Kit can be used within any platform or authentication system.{' '}
							<a
								href="https://developer.virgilsecurity.com/docs/e3kit/fundamentals/end-to-end-encryption/"
								target="_blank"
							>
								Learn more
							</a>
						</p>
					</Title>
					<ZoomImage src={schemeImg2x} disableDesktop={true}>
						<img
							className="instruction-img"
							alt="E3Kit can be used within any platform or authentication system."
							src={schemeImg}
							srcSet={schemeImg2x}
						/>
					</ZoomImage>
				</div>
			</section>
			<Benefits>
				<Title className="dark">
					<h2>Benefits</h2>
					<p>At least 6 reasons to choose Virgil E3Kit for data encryption in transit.</p>
				</Title>
				<BenefitsList>
					{BENEFITS.map((bn, index) => (
						<BenefitsItem {...bn} index={index} key={index} />
					))}
				</BenefitsList>
			</Benefits>
			<Standards />
			<Sample>
				<SampleTextContainer>
					<h3>
						From developers <br /> for developers
					</h3>
					<p>
						We provide cross-platform, transparent and easy-to-use libraries. Here are some code
						snippets from our E3Kit.
					</p>
					<RedButton href="https://developer.virgilsecurity.com/docs/e3kit/">
						Explore documentation
					</RedButton>
				</SampleTextContainer>
				<SampleCode>
					<E3KitSnippet />
				</SampleCode>
			</Sample>
			<BlogPostList>
				{E3KIT_POSTS.map((post, index) => (
					<BlogPost {...post} key={index} />
				))}
			</BlogPostList>
			<Conclusion>
				<em>Get started</em> now with our easy-to-follow guides.
				<br />
				Start <em>free</em>, pay $0.02/month per user beyond 250 users.
			</Conclusion>
			<Cases>
				{ALL_CASES.map((item, index) => (
					<CaseItem {...item} key={index} />
				))}
			</Cases>
		</Layout>
	);
};

export default E3KitPage;
