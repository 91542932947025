import React from 'react';
import TwilioIcon from '../../../styles/assets/images/e3kit/twilio.svg';
import FirebaseIcon from '../../../styles/assets/images/e3kit/Firebase.svg';
import NexmoIcon from '../../../styles/assets/images/e3kit/nexmo.svg';
import PubnubIcon from '../../../styles/assets/images/e3kit/pubnub.svg';
import Back4AppIcon from '../../../styles/assets/images/e3kit/Back4App.svg';

const FavoriteTech: React.FC = () => {
	return (
		<section className="favorite">
			<div className="wrapper">
				<div className="favorite-container">
					<div className="text">
						<span className="red">E3Kit</span> works with your favorite tech
					</div>
					<a href="https://developer.virgilsecurity.com/docs/e3kit/integrations/twilio/">
						<img src={TwilioIcon} alt="Twilio" />
					</a>
					<a href="https://developer.virgilsecurity.com/docs/e3kit/integrations/firebase/">
						<img src={FirebaseIcon} alt="Firebase" />
					</a>
					<a href="https://developer.virgilsecurity.com/docs/e3kit/integrations/nexmo/">
						<img src={NexmoIcon} alt="nexmo" />
					</a>
					<a href="https://developer.virgilsecurity.com/docs/e3kit/integrations/pubnub/">
						<img src={PubnubIcon} alt="PubNub" />
					</a>
					<a href="https://developer.virgilsecurity.com/docs/e3kit/integrations/back4app/">
						<img src={Back4AppIcon} alt="Back4App" />
					</a>
				</div>
			</div>
		</section>
	);
};

export default FavoriteTech;
